.rotate-animation {
    animation-name: rotate;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite; /* add this line */
  }
  
  @keyframes rotate {
    0% {
      transform:  rotateX(0deg) rotateY(0deg);
    }
    50% {
      transform:  rotateX(0deg) rotateY(90deg);
    }
    100% {
      transform:  rotateX(0deg) rotateY(0deg);
    }
  }
  