@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
  /* background-image: url("../img/opacity-10.png") !important;
  background-repeat: repeat !important;
  background-size: 600px  !important; */
}

option {
  color: black;
}

/* customize the scrollbar appearance */
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background: #7551ff;
}

/* customize the scrollbar appearance */
div::-webkit-scrollbar {
  width: 0;
}

div::-webkit-scrollbar-track {
    display: none;
  background: transparent;
}

div::-webkit-scrollbar-thumb {
    display: none;
  background: transparent;
}
